.menu {
  text-align: center;
  vertical-align: middle;
}

.menu__item {
  display: inline-block;

  @include minSM {
    & + & {
      border-left: 1px solid #4d4d4d;
    }
  }

  @include maxSM {
    display: block;

    & + & {
      border-top: 1px solid #00851c;
    }
  }
}

.menu__link {
  display: inline-block;
  padding: 0 7px;

  font-size: 16px;
  line-height: 1.25;
  color: #1b1b1b;

  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &--active {
    font-weight: 700;
    cursor: default;
    pointer-events: none;
  }

  @include maxSM {
    display: block;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
