.footer {
  font-size: 14px;
  background-color: #fff;

  @include minSM {
    .menu__item--header {
      display: none;
    }
  }

  @include maxSM {
    .container {
      display: flex;
      flex-direction: column;
    }
  }
}

.footer__top {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #99ca3c;
}

.footer__logo {
  padding-top: 20px;
  padding-bottom: 40px;

  text-align: center;
  color: #fff;
}

.footer__menu {
  .menu__item + .menu__item {
    border: none;
  }

  .menu__item--footer + .menu__item--footer {
    border-left: 1px solid #fff;
  }

  .menu__link {
    font-size: 18px;
    color: #fff;
  }
}

.footer__disclosures {
  padding-top: 40px;
  padding-bottom: 40px;

  .wysiwyg {
    color: #515151;

    p {
      font-size: inherit;
    }
  }
}

.footer__copyright {
  padding-bottom: 20px;
  color: #58595b;
  text-align: center;
  font-size: 16px;
}
