body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  color: #000;
  font-family: $primaryFont;
  font-size: 12px;
  line-height: 1.4;
}

.page_wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
}

.content {
  flex: 1 1 auto;
  padding-top: 20px;

  background-color: #f0f0f0;
}

.content__side {
  @include maxSM {
    margin-bottom: 20px;
  }
}

.section {
  padding-top: 40px;
  padding-bottom: 40px;
}

.row--reverse {
  @include maxSM {
    display: flex;
    flex-direction: column-reverse;
  }
}

.icon {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;

  svg {
    background: inherit;
    height: 100%;
    width: 100%;
    fill: inherit;
    vertical-align: top;
  }
}

.index_info {
  background-color: #fff;
  text-align: center;
}

.index_info__title {
  margin-bottom: 30px;

  font-weight: 700;
  font-size: 38px;
  color: #4f4f4f;
  line-height: 1.1;
}

.index_info__text {
  font-size: 16px;
  color: #797979;

  .h3, h3 {
    margin-bottom: 10px;
    font-size: 22px;
    font-weight: 700;
    color: #4f4f4f;
  }
}

.index_info__item {
  &:not(:last-child) {
    margin-bottom: 30px;
  }
}

.advance {
  text-align: center;
  color: #fff;

  background-color: #2d3a41;
  background-image: url("../images/bg-left.svg"), url("../images/bg-right.svg");
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat, no-repeat;
  background-size: 420px;

  p {
    margin-bottom: 5px;
    font-size: 18px;
  }

  @include maxSM {
    background-image: url("../images/bg-mobile.svg");
    background-position: 50% 0;
    background-size: cover;
  }
}

.advance__title {
  margin-bottom: 30px;
  font-size: 38px;
  font-weight: 500;

  span {
    color: #99ca3c;
  }

  @include maxSM {
    font-size: 28px;
  }
}
