.header {
  border-bottom: 1px solid rgba(88, 89, 91, 0.4);
  background-color: #fff;
  transition: 0.3s;
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $headerHeight;
  padding-top: 5px;
  padding-bottom: 5px;

  @include maxSM {
    height: $headerHeightMobile;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
  }
}

.header__menu {
  display: flex;

  @include maxSM {
    display: none;
  }
}

.logo {
  display: block;
  text-decoration: none;

}

.logo__txt {
  display: inline-block;
  font-size: 24px;
  line-height: 41px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}
