.form {
  &__header {
    padding: 15px 20px;

    font-family: $secondaryFont;
    font-size: 24px;
    color: #fff;
    text-align: center;
    background-color: #2d3a42;
  }

  &__body {
    padding: 15px 20px;
  }

  &__footer {
    padding-bottom: 15px;
  }

  &__row {
    &:not(:last-child) {
      margin-bottom: 20px;
    }
  }

  &__text {
    font-size: 14px;

    a {
      color: #ffcb05;
      text-decoration: underline;

      &:hover {
        text-decoration: none;
      }
    }
  }

  .btn {
    width: 100%;
  }

  .comodo_logo {
    display: flex;
    width: 200px;
    height: 50px;
    margin-right: auto;
    margin-left: auto;
    text-decoration: none;

    &__img {
      flex-shrink: 0;
      vertical-align: middle;
    }

    &:before {
      content: "SSL Secure Connection";
      display: block;
      width: 75px;
      margin-top: 10px;
      margin-right: 10px;
      flex-shrink: 0;
      font-size: 13px;
      font-weight: 700;
      color: #79c400;
    }
  }

  label.error {
    font-size: 11px;
    color: #f20;
  }

  &--short {
    margin-bottom: 20px;
    border-radius: 10px;
    background-color: #fff;
    overflow: hidden;
  }
}

.input,
.select {
  width: 100%;
  height: 50px;
  padding-left: 10px;

  border-radius: 3px;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  outline: none;

  font-size: 18px;
  color: #242424;

  &::placeholder {
    color: #242424;
  }
}

.input {
  padding-right: 10px;

  &.error {
    border-color: #f20;
  }
}

//.select {}

.btn {
  display: inline-block;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #99ca3c;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease;

  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: #fff;

  text-decoration: none;

  &:hover {
    background-color: #aed563;
    text-decoration: none;
  }

  &:focus {
    box-shadow: inset 0 0 0 2px rgba(#000, 0.3);
  }
}

.label__text {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #a1a1a1;
}

.message {
  display: none;
  margin-top: 20px;
  margin-bottom: 20px;

  font-size: 16px;
  line-height: 1.25;

  &--success {
    color: green;
  }

  &--error {
    color: #f20;
  }
}

.form_feature {
  margin: 20px auto;
}

.form_feature__head {
  padding: 15px;

  font-size: 22px;
  text-align: center;
  color: #fff;

  background-color: #99ca3b;
}

.form_feature__body {
  padding: 15px;

  background-color: #fff;

  li {
    position: relative;
    width: auto;
    padding: 5px 5px 5px 20px;
    color: #3f3f3f;
    font-size: 16px;
    font-style: italic;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background-color: #99ca3b;
      transform: translateY(-50%);
    }
  }
}
