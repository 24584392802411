.benefits {
  background-color: rgba(255, 255, 255, 0.8);
  border-bottom: 6px solid #99ca3c;
}

.benefits_list {
  .icon {
    display: block;
    height: 44px;
    margin-bottom: 15px;

    fill: #99ca3c;
  }

  @include minSM {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @include maxSM {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.benefits_list__item {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #2d3a41;

  @include minSM {
    padding-top: 18px;
    padding-bottom: 18px;
  }
  @include maxSM {
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
  }
}
