html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-family: inherit;
  vertical-align: baseline;
}

/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

body {
  font-family: Arial, sans-serif;
  line-height: 1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

html {
  line-height: 1;
  box-sizing: border-box;
}

* {
  box-sizing: inherit;
  font-family: inherit;
  color: inherit;
}

.container {
  max-width: 1170px;
  padding-right: 15px;
  padding-left: 15px;
}

.visible-xs,
.visible-sm,
.visible-md,
.visible-lg {
  display: none !important;
}

.visible-xs-block,
.visible-xs-inline,
.visible-xs-inline-block,
.visible-sm-block,
.visible-sm-inline,
.visible-sm-inline-block,
.visible-md-block,
.visible-md-inline,
.visible-md-inline-block,
.visible-lg-block,
.visible-lg-inline,
.visible-lg-inline-block {
  display: none !important;
}

@media (max-width: 767px) {
  .visible-xs {
    display: block !important;
  }
  table.visible-xs {
    display: table;
  }
  tr.visible-xs {
    display: table-row !important;
  }
  th.visible-xs,
  td.visible-xs {
    display: table-cell !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-block {
    display: block !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline {
    display: inline !important;
  }
}

@media (max-width: 767px) {
  .visible-xs-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .visible-sm {
    display: block !important;
  }
  table.visible-sm {
    display: table;
  }
  tr.visible-sm {
    display: table-row !important;
  }
  th.visible-sm,
  td.visible-sm {
    display: table-cell !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .visible-sm-block {
    display: block !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .visible-sm-inline {
    display: inline !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .visible-sm-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1024px) and (max-width: 1259px) {
  .visible-md {
    display: block !important;
  }
  table.visible-md {
    display: table;
  }
  tr.visible-md {
    display: table-row !important;
  }
  th.visible-md,
  td.visible-md {
    display: table-cell !important;
  }
}

@media (min-width: 1024px) and (max-width: 1259px) {
  .visible-md-block {
    display: block !important;
  }
}

@media (min-width: 1024px) and (max-width: 1259px) {
  .visible-md-inline {
    display: inline !important;
  }
}

@media (min-width: 1024px) and (max-width: 1259px) {
  .visible-md-inline-block {
    display: inline-block !important;
  }
}

@media (min-width: 1260px) {
  .visible-lg {
    display: block !important;
  }
  table.visible-lg {
    display: table;
  }
  tr.visible-lg {
    display: table-row !important;
  }
  th.visible-lg,
  td.visible-lg {
    display: table-cell !important;
  }
}

@media (min-width: 1260px) {
  .visible-lg-block {
    display: block !important;
  }
}

@media (min-width: 1260px) {
  .visible-lg-inline {
    display: inline !important;
  }
}

@media (min-width: 1260px) {
  .visible-lg-inline-block {
    display: inline-block !important;
  }
}

@media (max-width: 767px) {
  .hidden-xs {
    display: none !important;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  .hidden-sm {
    display: none !important;
  }
}

@media (min-width: 1024px) and (max-width: 1259px) {
  .hidden-md {
    display: none !important;
  }
}

@media (min-width: 1260px) {
  .hidden-lg {
    display: none !important;
  }
}

.visible-print {
  display: none !important;
}

@media print {
  .visible-print {
    display: block !important;
  }
  table.visible-print {
    display: table;
  }
  tr.visible-print {
    display: table-row !important;
  }
  th.visible-print,
  td.visible-print {
    display: table-cell !important;
  }
}

.visible-print-block {
  display: none !important;
}

@media print {
  .visible-print-block {
    display: block !important;
  }
}

.visible-print-inline {
  display: none !important;
}

@media print {
  .visible-print-inline {
    display: inline !important;
  }
}

.visible-print-inline-block {
  display: none !important;
}

@media print {
  .visible-print-inline-block {
    display: inline-block !important;
  }
}

@media print {
  .hidden-print {
    display: none !important;
  }
}

.container {
  margin-right: auto;
  margin-left: auto;
}

.container-fluid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.row {
  margin-left: -15px;
  margin-right: -15px;
}

.col,
.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 15px;
  padding-right: 15px;
}

.col,
.col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}

.col-xs-12 {
  width: 100%;
}

.col-xs-11 {
  width: 91.66666667%;
}

.col-xs-10 {
  width: 83.33333333%;
}

.col-xs-9 {
  width: 75%;
}

.col-xs-8 {
  width: 66.66666667%;
}

.col-xs-7 {
  width: 58.33333333%;
}

.col-xs-6 {
  width: 50%;
}

.col-xs-5 {
  width: 41.66666667%;
}

.col-xs-4 {
  width: 33.33333333%;
}

.col-xs-3 {
  width: 25%;
}

.col-xs-2 {
  width: 16.66666667%;
}

.col-xs-1 {
  width: 8.33333333%;
}

.col-xs-pull-12 {
  right: 100%;
}

.col-xs-pull-11 {
  right: 91.66666667%;
}

.col-xs-pull-10 {
  right: 83.33333333%;
}

.col-xs-pull-9 {
  right: 75%;
}

.col-xs-pull-8 {
  right: 66.66666667%;
}

.col-xs-pull-7 {
  right: 58.33333333%;
}

.col-xs-pull-6 {
  right: 50%;
}

.col-xs-pull-5 {
  right: 41.66666667%;
}

.col-xs-pull-4 {
  right: 33.33333333%;
}

.col-xs-pull-3 {
  right: 25%;
}

.col-xs-pull-2 {
  right: 16.66666667%;
}

.col-xs-pull-1 {
  right: 8.33333333%;
}

.col-xs-pull-0 {
  right: auto;
}

.col-xs-push-12 {
  left: 100%;
}

.col-xs-push-11 {
  left: 91.66666667%;
}

.col-xs-push-10 {
  left: 83.33333333%;
}

.col-xs-push-9 {
  left: 75%;
}

.col-xs-push-8 {
  left: 66.66666667%;
}

.col-xs-push-7 {
  left: 58.33333333%;
}

.col-xs-push-6 {
  left: 50%;
}

.col-xs-push-5 {
  left: 41.66666667%;
}

.col-xs-push-4 {
  left: 33.33333333%;
}

.col-xs-push-3 {
  left: 25%;
}

.col-xs-push-2 {
  left: 16.66666667%;
}

.col-xs-push-1 {
  left: 8.33333333%;
}

.col-xs-push-0 {
  left: auto;
}

.col-xs-offset-12 {
  margin-left: 100%;
}

.col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.col-xs-offset-9 {
  margin-left: 75%;
}

.col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.col-xs-offset-6 {
  margin-left: 50%;
}

.col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.col-xs-offset-3 {
  margin-left: 25%;
}

.col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.col-xs-offset-0 {
  margin-left: 0%;
}

@media (min-width: 768px) {
  .col,
  .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .col-sm-12 {
    width: 100%;
  }
  .col-sm-11 {
    width: 91.66666667%;
  }
  .col-sm-10 {
    width: 83.33333333%;
  }
  .col-sm-9 {
    width: 75%;
  }
  .col-sm-8 {
    width: 66.66666667%;
  }
  .col-sm-7 {
    width: 58.33333333%;
  }
  .col-sm-6 {
    width: 50%;
  }
  .col-sm-5 {
    width: 41.66666667%;
  }
  .col-sm-4 {
    width: 33.33333333%;
  }
  .col-sm-3 {
    width: 25%;
  }
  .col-sm-2 {
    width: 16.66666667%;
  }
  .col-sm-1 {
    width: 8.33333333%;
  }
  .col-sm-pull-12 {
    right: 100%;
  }
  .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .col-sm-pull-9 {
    right: 75%;
  }
  .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .col-sm-pull-6 {
    right: 50%;
  }
  .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .col-sm-pull-3 {
    right: 25%;
  }
  .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .col-sm-pull-0 {
    right: auto;
  }
  .col-sm-push-12 {
    left: 100%;
  }
  .col-sm-push-11 {
    left: 91.66666667%;
  }
  .col-sm-push-10 {
    left: 83.33333333%;
  }
  .col-sm-push-9 {
    left: 75%;
  }
  .col-sm-push-8 {
    left: 66.66666667%;
  }
  .col-sm-push-7 {
    left: 58.33333333%;
  }
  .col-sm-push-6 {
    left: 50%;
  }
  .col-sm-push-5 {
    left: 41.66666667%;
  }
  .col-sm-push-4 {
    left: 33.33333333%;
  }
  .col-sm-push-3 {
    left: 25%;
  }
  .col-sm-push-2 {
    left: 16.66666667%;
  }
  .col-sm-push-1 {
    left: 8.33333333%;
  }
  .col-sm-push-0 {
    left: auto;
  }
  .col-sm-offset-12 {
    margin-left: 100%;
  }
  .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-sm-offset-9 {
    margin-left: 75%;
  }
  .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-sm-offset-6 {
    margin-left: 50%;
  }
  .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-sm-offset-3 {
    margin-left: 25%;
  }
  .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-sm-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1024px) {
  .col,
  .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .col-md-12 {
    width: 100%;
  }
  .col-md-11 {
    width: 91.66666667%;
  }
  .col-md-10 {
    width: 83.33333333%;
  }
  .col-md-9 {
    width: 75%;
  }
  .col-md-8 {
    width: 66.66666667%;
  }
  .col-md-7 {
    width: 58.33333333%;
  }
  .col-md-6 {
    width: 50%;
  }
  .col-md-5 {
    width: 41.66666667%;
  }
  .col-md-4 {
    width: 33.33333333%;
  }
  .col-md-3 {
    width: 25%;
  }
  .col-md-2 {
    width: 16.66666667%;
  }
  .col-md-1 {
    width: 8.33333333%;
  }
  .col-md-pull-12 {
    right: 100%;
  }
  .col-md-pull-11 {
    right: 91.66666667%;
  }
  .col-md-pull-10 {
    right: 83.33333333%;
  }
  .col-md-pull-9 {
    right: 75%;
  }
  .col-md-pull-8 {
    right: 66.66666667%;
  }
  .col-md-pull-7 {
    right: 58.33333333%;
  }
  .col-md-pull-6 {
    right: 50%;
  }
  .col-md-pull-5 {
    right: 41.66666667%;
  }
  .col-md-pull-4 {
    right: 33.33333333%;
  }
  .col-md-pull-3 {
    right: 25%;
  }
  .col-md-pull-2 {
    right: 16.66666667%;
  }
  .col-md-pull-1 {
    right: 8.33333333%;
  }
  .col-md-pull-0 {
    right: auto;
  }
  .col-md-push-12 {
    left: 100%;
  }
  .col-md-push-11 {
    left: 91.66666667%;
  }
  .col-md-push-10 {
    left: 83.33333333%;
  }
  .col-md-push-9 {
    left: 75%;
  }
  .col-md-push-8 {
    left: 66.66666667%;
  }
  .col-md-push-7 {
    left: 58.33333333%;
  }
  .col-md-push-6 {
    left: 50%;
  }
  .col-md-push-5 {
    left: 41.66666667%;
  }
  .col-md-push-4 {
    left: 33.33333333%;
  }
  .col-md-push-3 {
    left: 25%;
  }
  .col-md-push-2 {
    left: 16.66666667%;
  }
  .col-md-push-1 {
    left: 8.33333333%;
  }
  .col-md-push-0 {
    left: auto;
  }
  .col-md-offset-12 {
    margin-left: 100%;
  }
  .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-md-offset-9 {
    margin-left: 75%;
  }
  .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-md-offset-6 {
    margin-left: 50%;
  }
  .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-md-offset-3 {
    margin-left: 25%;
  }
  .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-md-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1260px) {
  .col,
  .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  .col-lg-12 {
    width: 100%;
  }
  .col-lg-11 {
    width: 91.66666667%;
  }
  .col-lg-10 {
    width: 83.33333333%;
  }
  .col-lg-9 {
    width: 75%;
  }
  .col-lg-8 {
    width: 66.66666667%;
  }
  .col-lg-7 {
    width: 58.33333333%;
  }
  .col-lg-6 {
    width: 50%;
  }
  .col-lg-5 {
    width: 41.66666667%;
  }
  .col-lg-4 {
    width: 33.33333333%;
  }
  .col-lg-3 {
    width: 25%;
  }
  .col-lg-2 {
    width: 16.66666667%;
  }
  .col-lg-1 {
    width: 8.33333333%;
  }
  .col-lg-pull-12 {
    right: 100%;
  }
  .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .col-lg-pull-9 {
    right: 75%;
  }
  .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .col-lg-pull-6 {
    right: 50%;
  }
  .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .col-lg-pull-3 {
    right: 25%;
  }
  .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .col-lg-pull-0 {
    right: auto;
  }
  .col-lg-push-12 {
    left: 100%;
  }
  .col-lg-push-11 {
    left: 91.66666667%;
  }
  .col-lg-push-10 {
    left: 83.33333333%;
  }
  .col-lg-push-9 {
    left: 75%;
  }
  .col-lg-push-8 {
    left: 66.66666667%;
  }
  .col-lg-push-7 {
    left: 58.33333333%;
  }
  .col-lg-push-6 {
    left: 50%;
  }
  .col-lg-push-5 {
    left: 41.66666667%;
  }
  .col-lg-push-4 {
    left: 33.33333333%;
  }
  .col-lg-push-3 {
    left: 25%;
  }
  .col-lg-push-2 {
    left: 16.66666667%;
  }
  .col-lg-push-1 {
    left: 8.33333333%;
  }
  .col-lg-push-0 {
    left: auto;
  }
  .col-lg-offset-12 {
    margin-left: 100%;
  }
  .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .col-lg-offset-9 {
    margin-left: 75%;
  }
  .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .col-lg-offset-6 {
    margin-left: 50%;
  }
  .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .col-lg-offset-3 {
    margin-left: 25%;
  }
  .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .col-lg-offset-0 {
    margin-left: 0%;
  }
}

.container:before,
.container:after,
.container-fluid:before,
.container-fluid:after,
.row:before,
.row:after {
  content: " ";
  display: table;
}

.container:after,
.container-fluid:after,
.row:after {
  clear: both;
}

.wysiwyg {
  color: #2a2a2a;
}

.wysiwyg *:last-child {
  margin-bottom: 0;
}

.wysiwyg h2, .wysiwyg .h2,
.wysiwyg h3, .wysiwyg .h3,
.wysiwyg h4, .wysiwyg .h4,
.wysiwyg p {
  margin-bottom: 15px;
}

.wysiwyg h1, .wysiwyg .h1,
.wysiwyg h2, .wysiwyg .h2,
.wysiwyg h3, .wysiwyg .h3,
.wysiwyg h4, .wysiwyg .h4 {
  font-family: "Roboto Condensed", sans-serif;
  font-weight: 700;
  color: #58595b;
}

.wysiwyg h1, .wysiwyg .h1 {
  padding-bottom: 15px;
  margin-bottom: 20px;
  border-bottom: 3px solid #99ca3c;
  font-size: 32px;
}

.wysiwyg h2, .wysiwyg .h2 {
  font-size: 28px;
}

.wysiwyg h3, .wysiwyg .h3 {
  font-size: 24px;
}

.wysiwyg h4, .wysiwyg .h4 {
  font-size: 20px;
}

.wysiwyg p, .wysiwyg ul, .wysiwyg ol {
  font-size: 16px;
}

.wysiwyg ul, .wysiwyg ol {
  margin-bottom: 20px;
}

.wysiwyg ul {
  list-style: inside disc;
}

.wysiwyg ol {
  list-style: inside decimal;
}

.wysiwyg li {
  margin-bottom: 15px;
}

.wysiwyg a {
  color: #0c2d72;
}

.wysiwyg a:hover {
  color: #ef4023;
}

.wysiwyg a[href="#"] {
  color: currentColor;
  cursor: default;
}

.wysiwyg table {
  margin-bottom: 15px;
  font-size: 14px;
}

.wysiwyg th span, .wysiwyg td span {
  display: inline;
  word-break: break-all;
}

.wysiwyg th {
  padding: 10px;
  border: 1px solid #e9ffff;
  background-color: #99ca3c;
  color: #e9ffff;
  text-align: left;
  text-transform: uppercase;
  vertical-align: top;
}

@media (max-width: 768px) {
  .wysiwyg th {
    padding: 5px;
  }
}

.wysiwyg td {
  padding: 5px;
  border: 1px solid #ccc;
  vertical-align: top;
  border-spacing: 0;
  background-color: #fff;
}

body {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  color: #000;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 12px;
  line-height: 1.4;
}

.page_wrapper {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-height: 100vh;
}

.content {
  flex: 1 1 auto;
  padding-top: 20px;
  background-color: #f0f0f0;
}

@media (max-width: 768px) {
  .content__side {
    margin-bottom: 20px;
  }
}

.section {
  padding-top: 40px;
  padding-bottom: 40px;
}

@media (max-width: 768px) {
  .row--reverse {
    display: flex;
    flex-direction: column-reverse;
  }
}

.icon {
  display: inline-block;
  overflow: hidden;
  vertical-align: top;
}

.icon svg {
  background: inherit;
  height: 100%;
  width: 100%;
  fill: inherit;
  vertical-align: top;
}

.index_info {
  background-color: #fff;
  text-align: center;
}

.index_info__title {
  margin-bottom: 30px;
  font-weight: 700;
  font-size: 38px;
  color: #4f4f4f;
  line-height: 1.1;
}

.index_info__text {
  font-size: 16px;
  color: #797979;
}

.index_info__text .h3, .index_info__text h3 {
  margin-bottom: 10px;
  font-size: 22px;
  font-weight: 700;
  color: #4f4f4f;
}

.index_info__item:not(:last-child) {
  margin-bottom: 30px;
}

.advance {
  text-align: center;
  color: #fff;
  background-color: #2d3a41;
  background-image: url("../images/bg-left.svg"), url("../images/bg-right.svg");
  background-position: 0 0, 100% 0;
  background-repeat: no-repeat, no-repeat;
  background-size: 420px;
}

.advance p {
  margin-bottom: 5px;
  font-size: 18px;
}

@media (max-width: 768px) {
  .advance {
    background-image: url("../images/bg-mobile.svg");
    background-position: 50% 0;
    background-size: cover;
  }
}

.advance__title {
  margin-bottom: 30px;
  font-size: 38px;
  font-weight: 500;
}

.advance__title span {
  color: #99ca3c;
}

@media (max-width: 768px) {
  .advance__title {
    font-size: 28px;
  }
}

.header {
  border-bottom: 1px solid rgba(88, 89, 91, 0.4);
  background-color: #fff;
  transition: 0.3s;
}

.header__inner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 68px;
  padding-top: 5px;
  padding-bottom: 5px;
}

@media (max-width: 768px) {
  .header__inner {
    height: 54px;
    padding-top: 10px;
    padding-bottom: 10px;
    justify-content: center;
  }
}

.header__menu {
  display: flex;
}

@media (max-width: 768px) {
  .header__menu {
    display: none;
  }
}

.logo {
  display: block;
  text-decoration: none;
}

.logo__txt {
  display: inline-block;
  font-size: 24px;
  line-height: 41px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: middle;
}

.footer {
  font-size: 14px;
  background-color: #fff;
}

@media (min-width: 769px) {
  .footer .menu__item--header {
    display: none;
  }
}

@media (max-width: 768px) {
  .footer .container {
    display: flex;
    flex-direction: column;
  }
}

.footer__top {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #99ca3c;
}

.footer__logo {
  padding-top: 20px;
  padding-bottom: 40px;
  text-align: center;
  color: #fff;
}

.footer__menu .menu__item + .menu__item {
  border: none;
}

.footer__menu .menu__item--footer + .menu__item--footer {
  border-left: 1px solid #fff;
}

.footer__menu .menu__link {
  font-size: 18px;
  color: #fff;
}

.footer__disclosures {
  padding-top: 40px;
  padding-bottom: 40px;
}

.footer__disclosures .wysiwyg {
  color: #515151;
}

.footer__disclosures .wysiwyg p {
  font-size: inherit;
}

.footer__copyright {
  padding-bottom: 20px;
  color: #58595b;
  text-align: center;
  font-size: 16px;
}

.menu {
  text-align: center;
  vertical-align: middle;
}

.menu__item {
  display: inline-block;
}

@media (min-width: 769px) {
  .menu__item + .menu__item {
    border-left: 1px solid #4d4d4d;
  }
}

@media (max-width: 768px) {
  .menu__item {
    display: block;
  }
  .menu__item + .menu__item {
    border-top: 1px solid #00851c;
  }
}

.menu__link {
  display: inline-block;
  padding: 0 7px;
  font-size: 16px;
  line-height: 1.25;
  color: #1b1b1b;
  text-decoration: none;
}

.menu__link:hover {
  text-decoration: underline;
}

.menu__link--active {
  font-weight: 700;
  cursor: default;
  pointer-events: none;
}

@media (max-width: 768px) {
  .menu__link {
    display: block;
    padding-top: 4px;
    padding-bottom: 4px;
  }
}

.form_holder {
  background-image: url("../images/form_bg.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-color: #b2aba6;
}

.form_holder .container {
  width: 100%;
}

@media (min-width: 769px) {
  .form_holder {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  }
}

.form_holder_title_holder {
  text-align: center;
}

.form_holder__title,
.form_holder__sub_title {
  font-weight: 700;
  font-size: 64px;
  color: #2d3a41;
}

@media (max-width: 768px) {
  .form_holder__title,
  .form_holder__sub_title {
    font-size: 36px;
  }
}

.form_holder__sub_title {
  color: #99ca3c;
}

.form_holder__secure {
  margin: 20px auto;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  color: #5e5e5e;
}

.form_holder__secure span {
  display: inline-block;
  padding: 2px 2px 2px 25px;
  background-color: rgba(255, 255, 255, 0.5);
  background-image: url("../images/icon-04.svg");
  background-repeat: no-repeat;
  background-position: 8px 50%;
  background-size: 12px;
}

.benefits {
  background-color: rgba(255, 255, 255, 0.8);
  border-bottom: 6px solid #99ca3c;
}

.benefits_list .icon {
  display: block;
  height: 44px;
  margin-bottom: 15px;
  fill: #99ca3c;
}

@media (min-width: 769px) {
  .benefits_list {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 768px) {
  .benefits_list {
    padding-top: 20px;
    padding-bottom: 20px;
  }
}

.benefits_list__item {
  font-weight: 700;
  font-size: 24px;
  text-align: center;
  color: #2d3a41;
}

@media (min-width: 769px) {
  .benefits_list__item {
    padding-top: 18px;
    padding-bottom: 18px;
  }
}

@media (max-width: 768px) {
  .benefits_list__item:not(:last-of-type) {
    margin-bottom: 15px;
  }
}

.form__header {
  padding: 15px 20px;
  font-family: "Roboto Condensed", sans-serif;
  font-size: 24px;
  color: #fff;
  text-align: center;
  background-color: #2d3a42;
}

.form__body {
  padding: 15px 20px;
}

.form__footer {
  padding-bottom: 15px;
}

.form__row:not(:last-child) {
  margin-bottom: 20px;
}

.form__text {
  font-size: 14px;
}

.form__text a {
  color: #ffcb05;
  text-decoration: underline;
}

.form__text a:hover {
  text-decoration: none;
}

.form .btn {
  width: 100%;
}

.form .comodo_logo {
  display: flex;
  width: 200px;
  height: 50px;
  margin-right: auto;
  margin-left: auto;
  text-decoration: none;
}

.form .comodo_logo__img {
  flex-shrink: 0;
  vertical-align: middle;
}

.form .comodo_logo:before {
  content: "SSL Secure Connection";
  display: block;
  width: 75px;
  margin-top: 10px;
  margin-right: 10px;
  flex-shrink: 0;
  font-size: 13px;
  font-weight: 700;
  color: #79c400;
}

.form label.error {
  font-size: 11px;
  color: #f20;
}

.form--short {
  margin-bottom: 20px;
  border-radius: 10px;
  background-color: #fff;
  overflow: hidden;
}

.input,
.select {
  width: 100%;
  height: 50px;
  padding-left: 10px;
  border-radius: 3px;
  border: 1px solid #e8e8e8;
  background-color: #fff;
  outline: none;
  font-size: 18px;
  color: #242424;
}

.input::placeholder,
.select::placeholder {
  color: #242424;
}

.input {
  padding-right: 10px;
}

.input.error {
  border-color: #f20;
}

.btn {
  display: inline-block;
  padding-top: 8px;
  padding-bottom: 8px;
  background-color: #99ca3c;
  border-radius: 8px;
  border: none;
  outline: none;
  cursor: pointer;
  transition: 0.3s ease;
  font-size: 32px;
  font-weight: 700;
  text-align: center;
  color: #fff;
  text-decoration: none;
}

.btn:hover {
  background-color: #aed563;
  text-decoration: none;
}

.btn:focus {
  box-shadow: inset 0 0 0 2px rgba(0, 0, 0, 0.3);
}

.label__text {
  display: inline-block;
  margin-bottom: 5px;
  font-weight: 400;
  font-size: 14px;
  color: #a1a1a1;
}

.message {
  display: none;
  margin-top: 20px;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.25;
}

.message--success {
  color: green;
}

.message--error {
  color: #f20;
}

.form_feature {
  margin: 20px auto;
}

.form_feature__head {
  padding: 15px;
  font-size: 22px;
  text-align: center;
  color: #fff;
  background-color: #99ca3b;
}

.form_feature__body {
  padding: 15px;
  background-color: #fff;
}

.form_feature__body li {
  position: relative;
  width: auto;
  padding: 5px 5px 5px 20px;
  color: #3f3f3f;
  font-size: 16px;
  font-style: italic;
}

.form_feature__body li:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: #99ca3b;
  transform: translateY(-50%);
}

.unsubscribe .form--unsubscribe {
  margin-bottom: 30px;
  padding: 20px;
  background-color: #fff;
  border-radius: 7px;
}

.unsubscribe .btn {
  font-size: 23px;
}

.unsubscribe .form__row {
  margin-bottom: 15px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  opacity: 0;
  visibility: hidden;
  background-color: rgba(0, 0, 0, 0.4);
  transition: 0.3s ease;
}

.overlay__container {
  position: absolute;
  top: 50px;
  left: 5px;
  right: 5px;
  bottom: 50px;
  z-index: 1;
}

.overlay__content {
  border-radius: 5px;
  padding: 10px 30px;
  max-width: 1000px;
  max-height: 100%;
  margin-left: auto;
  margin-right: auto;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #fff;
}

.overlay__close {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 10;
  display: block;
  width: 30px;
  height: 30px;
  color: #fff;
  cursor: pointer;
}

.overlay__close:before, .overlay__close:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  display: block;
  width: 20px;
  height: 2px;
  background-color: currentColor;
}

.overlay__close:before {
  transform: translate(-50%, -50%) rotate(45deg);
}

.overlay__close:after {
  transform: translate(-50%, -50%) rotate(-45deg);
}

@media (max-width: 768px) {
  .overlay__container {
    left: 0;
    right: 0;
    bottom: 0;
  }
  .overlay__content {
    padding: 10px;
    border-radius: 0;
  }
  .overlay__close, .overlay__close:before {
    width: auto;
    top: 0;
    left: 0;
    right: 0;
    transform: none;
    background-color: transparent;
  }
  .overlay__close {
    height: 50px;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.8);
    color: #000;
  }
  .overlay__close:before {
    content: 'Tap to Close';
    height: auto;
    font-size: 24px;
    line-height: 50px;
    color: #fff;
  }
  .overlay__close:after {
    display: none;
  }
}

html.js_popup_is_open, html.js_popup_is_open body {
  position: relative;
  height: 100%;
  overflow: hidden;
}

@media (min-width: 769px) {
  .page_form .header,
  .page_index .header {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    border-bottom: none;
    background-color: transparent;
  }
  .page_form .form_holder,
  .page_index .form_holder {
    padding-top: calc(68px + 25px);
  }
}

.page_form .content,
.page_index .content {
  padding-top: 0;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .page_index .form_holder {
    min-height: auto;
  }
}

@media (min-width: 769px) {
  .page_form .form_holder {
    justify-content: center;
  }
}
