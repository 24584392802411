.page_form,
.page_index {
  @include minSM {
    .header {
      position: absolute;
      top: 0;
      left: 0;
      z-index: 100;
      width: 100%;
      border-bottom: none;
      background-color: transparent;
    }

    .form_holder {
      padding-top: calc(#{$headerHeight} + 25px);
    }
  }

  .content {
    padding-top: 0;
  }
}

.page_index {
  .form_holder {
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      min-height: auto;
    }
  }
}
