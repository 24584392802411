.form_holder {
  background-image: url("../images/form_bg.jpg");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  background-color: #b2aba6;

  .container {
    width: 100%;
  }

  @include minSM {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: 100vh;
  }
}

.form_holder_title_holder {
  text-align: center;
}

.form_holder__title,
.form_holder__sub_title {
  font-weight: 700;
  font-size: 64px;
  color: #2d3a41;

  @include maxSM {
    font-size: 36px;
  }
}

.form_holder__sub_title {
  color: #99ca3c;
}

.form_holder__secure {
  margin: 20px auto;
  text-align: center;
  font-weight: 700;
  font-size: 12px;
  color: #5e5e5e;

  span {
    display: inline-block;
    padding: 2px 2px 2px 25px;
    background-color: rgba(255, 255, 255, 0.5);
    background-image: url("../images/icon-04.svg");
    background-repeat: no-repeat;
    background-position: 8px 50%;
    background-size: 12px;
  }
}
