.wysiwyg {
  color: #2a2a2a;

  *:last-child {
    margin-bottom: 0;
  }

  h2, .h2,
  h3, .h3,
  h4, .h4,
  p {
    margin-bottom: 15px;
  }

  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4 {
    font-family: $secondaryFont;
    font-weight: 700;
    color: #58595b;
  }

  h1, .h1 {
    padding-bottom: 15px;
    margin-bottom: 20px;
    border-bottom: 3px solid #99ca3c;
    font-size: 32px;
  }

  h2, .h2 {
    font-size: 28px;
  }

  h3, .h3 {
    font-size: 24px;
  }

  h4, .h4 {
    font-size: 20px;
  }

  p, ul, ol {
    font-size: 16px;
  }

  ul, ol {
    margin-bottom: 20px;
  }

  ul {
    list-style: inside disc;
  }

  ol {
    list-style: inside decimal;
  }

  li {
    margin-bottom: 15px;
  }

  a {
    color: #0c2d72;

    &:hover {
      color: #ef4023;
    }

    &[href="#"] {
      color: currentColor;
      cursor: default;
    }
  }

  table {
    margin-bottom: 15px;
    font-size: 14px;
  }

  th, td {
    span {
      display: inline;
      word-break: break-all;
    }
  }

  th {
    padding: 10px;
    border: 1px solid #e9ffff;
    background-color: #99ca3c;
    color: #e9ffff;
    text-align: left;
    text-transform: uppercase;
    vertical-align: top;
    @include maxSM {
      padding: 5px;
    }
  }

  td {
    padding: 5px;
    border: 1px solid #ccc;
    vertical-align: top;
    border-spacing: 0;
    background-color: #fff;
  }
}
